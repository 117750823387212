import React from "react";
import { formatAmount, handleCapitalizeMultiple } from "../../Utils";
import CommonTable from "../Common/CommonTable";

const LiquidityDataTable = ({ handleSort, sortOrder, isLoadingGetData, liquidityData }) => {
  const columns = [
    {
      key: "id",
      label: "No.",
    },
    // {
    //   key: "from_address",
    //   label: "From Address",
    //   type: "address",
    //   truncate: true,
    // },
    // {
    //   key: "to_address",
    //   label: "To Address",
    //   type: "address",
    //   truncate: true,
    // },
    {
      key: "block",
      label: "Block",
      type: "link",
      redirectLinkPrefix: "https://basescan.org/block/",
    },
    {
      key: "tx_hash",
      label: "Transaction Hash",
      type: "address",
      tooltipContent: "to_account",
      tooltipColor: "success",
      truncate: true,
      redirectLinkPrefix: "https://basescan.org/tx/",
    },
    {
      key: "type",
      label: "Type",
      render: (item) => <span className={`badge badge-light-${item?.type === "BUY" || item?.type === "BUY AVAIL" ? "success" : "danger"}`}>{handleCapitalizeMultiple(item?.type)}</span>,
    },
    {
      key: "usdc_amount",
      label: "USDC Amount",
      type: "amount",
    },
    {
      key: "avail_amount",
      label: "Avail Amount",
      type: "amount",
    },
    {
      key: "price_usdc",
      label: "Price Usdc",
      type: "amount",
    },
    {
      key: "slippage",
      label: "Slippage",
      render: (item) => formatAmount(item?.slippage) + "%",
    },

    {
      key: "updated_at",
      label: "Updated At ",
      type: "date",
    },
  ];

  return <CommonTable columns={columns} data={liquidityData?.data || []} isLoading={isLoadingGetData} handleSort={handleSort} sortOrder={sortOrder} tableClass="transaction-table" />;
};

export default LiquidityDataTable;
