import { useEffect, useState } from "react";
import SidebarMenuList from "./SidebarMenuList";
import { useGetApi } from "../../Api/useGetApi";
import { useAuth } from "../../Context/AuthContext";

const Sidebar = ({ setIsSidebarOpen, isSidebarOpen }) => {
  const [activeMenu, setActiveMenu] = useState([]);
  const { isAuthenticated, setAccountData, setRole } = useAuth();

  const token = localStorage.getItem("finance_token");

  const { data, refetch, isLoading, fetchStatus } = useGetApi({ url: "/api/user/self", options: { enabled: false, refetchOnWidowFocus: false } });

  useEffect(() => {
    isAuthenticated && fetchStatus == "idle" && refetch();
  }, []);

  useEffect(() => {
    if (data && !isLoading) {
      localStorage.setItem("role", JSON.stringify(data?.role));
      setRole(data?.role?.name);
    }
    setAccountData(data);
  }, [token, data, isLoading]);

  return (
    <div className={`sidebar-wrapper ${isSidebarOpen ? "close_icon" : ""} custom-scroll`}>
      <div>
        <nav className="sidebar-main">
          <div id="sidebar-menu">
            <ul className="sidebar-links" id="simple-bar">
              <li className="back-btn w-100">
                {/* <img src="/assets/images/logo.png" className="logo" height={40} width={100} alt="" /> */}
                <h2 className="text-white">Logo</h2>
                <div className="mobile-back text-end ">
                  <span className="text-white" onClick={() => setIsSidebarOpen(!isSidebarOpen)}>
                    <i className="fa-solid fa-bars"></i>
                  </span>
                </div>
              </li>
              <li className="icon-sidebar">
                {/* <img src="/assets/images/logo.png" className="logo" height={40} width={40} alt="" /> */}
                <h2 className="text-white">Logo</h2>
              </li>
              <SidebarMenuList activeMenu={activeMenu} setActiveMenu={setActiveMenu} />
            </ul>
          </div>
        </nav>
      </div>
      {/* <div className="mt-5 logout-btn">
        <Button onClick={() => logout(navigate)}>Log out</Button>
      </div> */}
    </div>
  );
};

export default Sidebar;
