import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useAuth } from "../../../Context/AuthContext";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "react-router-dom";
import { Button, Form, FormGroup, Label } from "reactstrap";
import { Eye, EyeOff, Key } from "react-feather";

const UpdatePasswordContainer = ({ email, token }) => {
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [updateSpin, SetUpdateSpin] = useState(false);
  const toggleShow = () => setShow(!show);
  const toggleShow1 = () => setShow1(!show1);
  const { resetPassSchema, handleResetPassword } = useAuth();
  const { register,handleSubmit,formState: { errors }} = useForm({ resolver: yupResolver(resetPassSchema) });
  const navigate = useNavigate();

  const onSubmit = (data) => handleResetPassword({ email, token, data, navigate, SetUpdateSpin });

  return (
    <Form className="theme-form" onSubmit={handleSubmit(onSubmit)} noValidate>
      <h2>Update Password</h2>
        <FormGroup className="form-group">
          <Label>Password</Label>
          <input className="form-control" {...register("password")} autoComplete="off" type={show ? "text" : "password"} placeholder="Enter Password ..." />
          <Key />
          <div className={`toggle-password ${show ? "show" : ""}`} onClick={toggleShow}>
            {show ? <Eye />: <EyeOff/>}
          </div>
          {errors.password && <span className="text-danger fs-6">{errors.password.message}</span>}
        </FormGroup>
        <FormGroup className="form-group">
          <Label>Confirm Password</Label>
          <input className="form-control" {...register("confirmPassword")} autoComplete="off" type={show ? "text" : "password"} placeholder="Enter Confirm Password ..." />
          <Key />
          <div className={`toggle-password ${show ? "show" : ""}`} onClick={toggleShow1}>
            {show1 ? <Eye />: <EyeOff/>}
          </div>
          {errors.confirmPassword && <span className="text-danger fs-6">{errors.confirmPassword.message}</span>}
        </FormGroup>

        <FormGroup className="mb-0 loader-btn">
          <Button disabled={updateSpin ? true : false} type="submit" color="" className="btn-solid">
            Confirm Password {updateSpin && <div className="loader"></div>}
          </Button>
        </FormGroup>
    </Form>
  );
};

export default UpdatePasswordContainer;
