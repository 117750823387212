import { web3FromSource } from "@polkadot/extension-dapp";
import { hexToU8a } from "@polkadot/util";
import React, { Fragment, useState } from "react";
import { Button, Card, CardBody, CardHeader, Col, FormGroup, Row } from "reactstrap";
import { useWallet } from "../../Context/WalletContext";
import TableTop from "../Common/TableTop";
import { dynamicSvg } from "../../Utils";
import { Href } from "../../Constants";
import Loader from "../../Layout/Loader";

const TransactionDecoders = () => {
  const { api, selectedAccount } = useWallet();
  const [callData, setCallData] = useState("");
  const [decodedData, setDecodedData] = useState(null);
  const [isDecoding, setIsDecoding] = useState(false);

  const decodeCallData = async () => {
    if (!api || !callData) return;
    setIsDecoding(true);

    try {
      // Clean the input hex string
      const cleanHex = callData.startsWith("0x") ? callData : `0x${callData}`;

      // Convert hex to Uint8Array
      const callDataU8a = hexToU8a(cleanHex);

      // Try to decode as Call directly
      const decodedCall = api.createType("Call", callDataU8a);

      // Get the call index
      const callIndex = decodedCall.callIndex;

      // Find the call in metadata
      const { method, section } = api.registry.findMetaCall(callIndex);

      // Get the arguments
      const args = decodedCall.args.map((arg) => arg.toHuman());

      // Set the decoded information
      setDecodedData({
        section,
        method,
        args,
        callIndex: callIndex.toString(),
        raw: {
          hex: cleanHex,
          bytes: Array.from(callDataU8a),
        },
      });
    } catch (error) {
      console.error("Decode error:", error);

      // Try alternative decoding method if first attempt fails
      try {
        const registry = api.registry;
        const metadata = api.runtimeMetadata.asLatest;

        // Get the first two bytes for pallet and call indices
        const callDataArray = Array.from(hexToU8a(callData));
        const palletIndex = callDataArray[0];
        const callIndex = callDataArray[1];

        // Find the pallet
        const pallet = metadata.pallets.find((p) => p.index.toNumber() === palletIndex);

        if (!pallet) {
          throw new Error(`Pallet with index ${palletIndex} not found`);
        }

        // Get the calls for this pallet
        if (!pallet.calls.isSome) {
          throw new Error(`No calls found for pallet ${pallet.name.toString()}`);
        }

        const calls = pallet.calls.unwrap();
        const callVariants = calls.type.def.asVariant.variants;
        const call = callVariants.find((v) => v.index.toNumber() === callIndex);

        if (!call) {
          throw new Error(`Call with index ${callIndex} not found`);
        }

        setDecodedData({
          section: pallet.name.toString(),
          method: call.name.toString(),
          args: "Arguments could not be decoded. Raw data provided.",
          raw: {
            hex: callData,
            bytes: callDataArray,
          },
          indices: {
            pallet: palletIndex,
            call: callIndex,
          },
        });

        console.log("Decoded using fallback method:", {
          pallet: pallet.name.toString(),
          call: call.name.toString(),
        });
      } catch (fallbackError) {
        console.error("Fallback decode error:", fallbackError);
        setDecodedData({
          error: `${error.message}\nFallback error: ${fallbackError.message}`,
          input: callData,
        });
      }
    } finally {
      setIsDecoding(false);
    }
  };

  // const signTransaction = async () => {
  //   if (!api || !selectedAccount || !decodedData || decodedData.error) return "hello";

  //   try {
  //     console.log("Starting proxy transaction signing...");

  //     // Get the injector for the selected account
  //     const injector = await web3FromSource(selectedAccount.meta.source);

  //     const innerCall = api.tx["balances"]["transferKeepAlive"](
  //       decodedData.args[2].args.dest.Id, // destination address
  //       decodedData.args[2].args.value.replace(/,/g, "") // remove commas from value
  //     );
  //     console.log("Inner call created:", innerCall.toHuman());

  //     // Create the proxy transaction
  //     const proxyTx = api.tx.proxy.proxy(
  //       decodedData.args[0].Id, // real account
  //       decodedData.args[1], // force proxy type (null in this case)
  //       innerCall // the inner call
  //     );
  //     console.log("Proxy transaction created:", proxyTx.toHuman());

  //     // Sign and send the transaction
  //     const unsub = await proxyTx
  //       .signAndSend(
  //         selectedAccount.address,
  //         {
  //           signer: injector.signer,
  //           nonce: -1, // Let the system handle nonce
  //         },
  //         (result) => {
  //           console.log("Transaction status:", result.status.type);

  //           if (result.status.isInBlock) {
  //             console.log("Included at block hash", result.status.asInBlock.toHex());
  //             alert(`Transaction included in block: ${result.status.asInBlock.toHex()}`);
  //           } else if (result.status.isFinalized) {
  //             console.log("Finalized block hash", result.status.asFinalized.toHex());
  //             alert(`Transaction finalized in block: ${result.status.asFinalized.toHex()}`);
  //             unsub();
  //           } else if (result.isError) {
  //             console.error("Transaction error:", result.toString());
  //             alert(`Transaction failed: ${result.toString()}`);
  //             unsub();
  //           }
  //         }
  //       )
  //       .catch((error) => {
  //         console.error("SignAndSend error:", error);
  //         throw error;
  //       });

  //     console.log("Transaction submitted");
  //   } catch (error) {
  //     console.error("Detailed signing error:", error);
  //     console.error("Error stack:", error.stack);
  //     alert(`Failed to sign transaction: ${error.message}`);
  //   }
  // };

  const signTransaction = async () => {
    if (!api || !selectedAccount || !decodedData || decodedData.error) {
      alert("Incomplete setup: Make sure the API is connected, wallet is selected, and the transaction data is decoded.");
      return;
    }
    try {
      const injector = await web3FromSource(selectedAccount.meta.source);
      const fromAddress = selectedAccount?.address;
      const toAddress = decodedData?.args[0]?.Id;
      const randomAmount = 1000000;

      // Create a extrinsic, transferring randomAmount units to toAddress.
      const transfer = await api.tx.balances.transferKeepAlive(toAddress, randomAmount);

      // Sign and Send the transaction
      await transfer.signAndSend(fromAddress,{ signer:injector.signer, appId:"0",callHash:'0xd2546dffba67d03a09b1ad34da977a846e177f60ae201d036dec5d0178b6a737'},  ({ events = [], status }) => {
        if (status.isInBlock) {
          console.log("Successful transfer of " + randomAmount + " with hash " + status.asInBlock.toHex());
        } else {
          console.log("Status of transfer: " + status.type);
        }

        events.forEach(({ phase, event: { data, method, section } }) => {
          console.log(phase.toString() + " : " + section + "." + method + " " + data.toString());
        });
      })
    } catch (err) {
      throw err;
    }
    // try {
    //   console.log("Preparing transaction for signing...");

    //   // Get the injector for the selected account
    //   const injector = await web3FromSource(selectedAccount.meta.source);

    //   // Create the transaction using the decoded data
    //   const { section, method, args } = decodedData;

    //   if (!api.tx[section] || !api.tx[section][method]) {
    //     throw new Error(`Invalid transaction method: ${section}.${method}`);
    //   }

    //   // Prepare the transaction
    //   const tx = api.tx[section][method](...args);

    //   console.log("Transaction details:", tx.toHuman());

    //   // Sign and send the transaction
    //   await tx.signAndSend(selectedAccount.address, { signer: injector.signer }, ({ status, events, dispatchError }) => {
    //     console.log("🚀 ~ awaittx.signAndSend ~ status:", status);

    //     if (status.isInBlock) {
    //       console.log(`Transaction included in block ${status.asInBlock.toHex()}`);
    //       alert(`Transaction is included in block: ${status.asInBlock.toHex()}`);
    //     } else if (status.isFinalized) {
    //       console.log(`Transaction finalized in block ${status.asFinalized.toHex()}`);
    //       alert(`Transaction is finalized in block: ${status.asFinalized.toHex()}`);
    //       // unsub();
    //     }

    //     // Handle errors
    //     if (dispatchError) {
    //       if (dispatchError.isModule) {
    //         const decoded = api.registry.findMetaError(dispatchError.asModule);
    //         const { section, name, docs } = decoded;
    //         console.error(`Error: ${section}.${name}: ${docs.join(" ")}`);
    //       } else {
    //         console.error(`Error: ${dispatchError.toString()}`);
    //       }
    //       alert(`Transaction failed: ${dispatchError.toString()}`);
    //       // unsub();
    //     }
    //   });

    //   console.log("Transaction submitted successfully!");
    // } catch (error) {
    //   console.error("Error signing transaction:", error);
    //   alert(`Error signing transaction: ${error.message}`);
    // }
  };

  if (!api) return <Loader />;


  return (
    <Fragment>
      <section className="common-table custom-decode-table">
        <Row>
          <Col xxl="8" xl="10" className="m-auto">
            <Card>
              <CardHeader>
                <h4>Avail Transaction Decoder </h4>
              </CardHeader>
              <CardBody>
                <TableTop noPaginate noSearchbar />
                <Row className="g-3">
                  <Col sm="12">
                    <FormGroup className="form-group custom-label-data">
                      <label className="mb-2 form-label ">Avail Transaction Data :</label>
                      <textarea className="form-control search-transaction" placeholder="Enter Avail transaction call data (hex format)..." value={callData} onChange={(e) => setCallData(e.target.value)} />
                    </FormGroup>
                  </Col>
                </Row>

                <Button color="transparent" className="btn-dark me-2" onClick={decodeCallData}>
                  {isDecoding ? "Decoding..." : "Decode"}
                </Button>
                <Button color="lo" className="border border-1" onClick={signTransaction}>
                  Sign Transaction
                </Button>

                {decodedData && (
                  <>
                    <div className="card data-wallet-card mt-4">
                      <div className="card-header border-0 p-0">
                        <div className="row">
                          <div className="col-3">
                            <div className="custom-data-label">
                              <label>decoded call</label>
                              <span>{decodedData?.section}</span>
                            </div>
                          </div>
                          <div className="col-5">
                            <div>
                              <span>{decodedData?.method}(dest, value)</span>
                            </div>
                          </div>
                          <div className="col-4">
                            <div>
                              <span>See [`Pallet::transfer_keep_alive`].</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card-body">
                        <div className="custom-wallet-body">
                          <div className="custom-ring">
                            <img className="img-fluid" src={dynamicSvg("ring-wallet.svg")} alt="icon" />
                          </div>
                          <span className="custom-data-label">dest: MultiAddress</span>
                          <div className="d-flex justify-content-between align-items-center">
                            <span>{decodedData?.args?.Id}</span>
                          </div>
                        </div>
                        <div className="custom-wallet-body mt-3">
                          <span className="custom-data-label">value: Compact'</span>
                          <div className="d-flex justify-content-between align-items-center">
                            <span>{ decodedData?.args?.[1]}</span>
                          </div>
                        </div>
                      </div>
                      <div className="card-footer custom-wallet-footer border-0">
                        <div className="custom-data-call">
                          <span className="custom-data-title d-block">encoded call hash</span>
                          <a href={Href}>0xe8e02c790c4d3723f3cafd4dfdf95688435b98d5c0da2032f902fbe80492edd3</a>
                        </div>
                        <div className="custom-data-copy">
                          <i className="fa-regular fa-copy" />
                          {/* <img className="img-fluid" src={dynamicSvg("copy-link.svg")} alt="icon" /> */}
                        </div>
                      </div>
                    </div>
                    <div class="custom-roll-card">
                      <div class="custom-card__header">Encoding Details</div>
                      <div class="custom-card__body">
                        <p>
                          <span>Call Index:</span> {decodedData?.callIndex}
                        </p>
                        <p>
                          <span>Dest:</span> 00 cdea0030caf5744fd882aff96a0ef95fb27e15ed83b4d8c7bfc13afd7db9e96
                        </p>
                        <p>
                          <span>Value:</span> 13000064a73b6e00d
                        </p>
                        <div className="custom-data-link mt-2">
                          <a href="#/extrinsics/decode/0x060300cdea0030caf5744fd882aff96a0ef95fb27e15ed83b4d8c7bfc13afd7db9e9613">View Link</a>
                          <i className="fa-solid fa-up-right-from-square ms-1"></i>
                        </div>
                      </div>
                    </div>
                    <div className="border p-3 rounded mt-3">
                      <h2 className="text-xl font-semibold mb-2">Decoded Transaction</h2>
                      <pre className="bg-gray-100 p-2 rounded whitespace-pre-wrap">{JSON.stringify(decodedData, null, 2)}</pre>
                    </div>
                  </>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </section>
    </Fragment>
  );
};

export default TransactionDecoders;
