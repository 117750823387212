import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Button, Col, Form, FormGroup, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { updateBridgeTransferApi } from "../../Api/ApiList";
import { usePerformMutation } from "../../Api/PerformMutation";
import { useBridgerTransfer } from "../../Context/BridgeTransferContext";

const EditModal = ({ refetchTransaction }) => {
  const { editModal, toggleEdit, editBridgeTransferData } = useBridgerTransfer();
  const [loading, setLoading] = useState(false); // State to track loading status

  const { register, handleSubmit, reset } = useForm({
    defaultValues: {
      id: editBridgeTransferData?.id,
      from_account: editBridgeTransferData?.from_account,
      to_account: editBridgeTransferData?.to_account,
    },
  });

  const { mutate } = usePerformMutation(
    () => {
      refetchTransaction();
      reset();
      toggleEdit();
    },
    () => {
      setLoading(false);
    }
  );

  const handleTransaction = async (data) => {
    setLoading(true);
    const { from_label, to_label, id } = data;

    const conditionalData = {
      id,
      from_label,
      to_label,
    };
    mutate({ url: updateBridgeTransferApi, data: conditionalData });
    setLoading(false);
  };

  return (
    <Modal size="lg" centered isOpen={editModal} toggle={toggleEdit} className="transaction-modal">
      <ModalHeader toggle={toggleEdit}>Edit Transaction</ModalHeader>
      <Form onSubmit={handleSubmit(handleTransaction)} noValidate>
        <ModalBody>
          <Row className="g-3">
            <Col sm="12">
              <FormGroup className="form-group">
                <label className="mb-2 form-label">From Account :</label>
                <input disabled {...register("from_account")} className="form-control search-transaction" type="text" placeholder="Enter from account" value={editBridgeTransferData?.from_account} />
              </FormGroup>
            </Col>
            <Col sm="12">
              <FormGroup className="form-group">
                <label className="mb-2 form-label">To Account :</label>
                <input disabled {...register("to_account")} className="form-control search-transaction" type="text" placeholder="Enter to account" value={editBridgeTransferData?.to_account} />
              </FormGroup>
            </Col>
            <Col sm="6">
              <FormGroup className="form-group">
                <label className="mb-2 form-label">From Label :</label>
                <input {...register("from_label")} className="form-control search-transaction" type="text" placeholder="Enter from label" defaultValue={editBridgeTransferData?.from_label} />
              </FormGroup>
            </Col>
            <Col sm="6">
              <FormGroup className="form-group">
                <label className="mb-2 form-label">To Label :</label>
                <input {...register("to_label")} className="form-control search-transaction" type="text" placeholder="Enter to label" defaultValue={editBridgeTransferData?.to_label} />
              </FormGroup>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" type="submit">
            Update {loading && <i className="fa-solid fa-circle-notch fa-spin"></i>}
          </Button>
          <Button color="light" className="border border-1" onClick={toggleEdit}>
            Cancel
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default EditModal;
