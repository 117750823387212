import React, { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getBridgeTransfersApi, refreshAccountApi } from "../../Api/ApiList";
import { useGetApi } from "../../Api/useGetApi";
import { useBridgerTransfer } from "../../Context/BridgeTransferContext";
import useDebounce from "../../Hooks/useDebounce";
import useDebouncedObject from "../../Hooks/useDebounceObject";
import AdvanceFilter from "../Common/AdvanceFilter";
import CommonPagination from "../Common/CommonPagination";
import TableTop from "../Common/TableTop";
import BridgeTransferDataTable from "./BridgeTransferDataTable";
import EditModal from "./EditModal";
import MessageTabs from "./MessageTabs";
import { Card, CardBody, CardHeader } from "reactstrap";
import Loader from "../../Layout/Loader";

const BridgeTransferContainer = () => {
  const [totalPages, setTotalPages] = useState();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(25);
  const [messageType, setMessageType] = useState("send");
  const [sortOrder, setSortOrder] = useState(true);
  const [sortColumn, setSortColumn] = useState("timestamp");
  const [showFilter, setShowFilter] = useState(false);
  const navigate = useNavigate();

  const [advancedFilter, setAdvancedFilter] = useState({
    startDate: null,
    endDate: null,
    minAmount: null,
    maxAmount: null,
    fromAddress: null,
    toAddress: null,
  });

  const debouncedObject = useDebouncedObject(advancedFilter, 500);
  const { editModal,  searchTerm, setSearchTerm } = useBridgerTransfer();
  const { startDate = null, endDate = null, minAmount = null, maxAmount = null, fromAddress = null, toAddress = null } = debouncedObject;
  const search = useDebounce(searchTerm?.trim(), 500);
  const [params, setParams] = useState({});
  const { isLoading: isLoadingGetData, data: bridgeData, refetch: refetchGetData } = useGetApi({ url: `/${getBridgeTransfersApi}`, params, options: { enabled: !!Object.keys(params).length }, successFunction: false, navigate: navigate });
  const { data: refreshedData, refetch: refreshData } = useGetApi({ url: refreshAccountApi, options: { enabled: false, refetchOnMount: false }, successFunction: false, navigate: navigate });

  const handleMessage = (message) => {
    setMessageType(message);
    setPage(1);
  };

  useEffect(() => {
    if (searchTerm?.trim() === "") {
      setPage(1);
    }
  }, [searchTerm]);

  // send advance filter params in api
  useEffect(() => {
    // Check if `from_address` and `to_address` are not empty
    if (fromAddress?.trim() !== "" && toAddress?.trim() !== "") {
      setParams({
        ...params,
        startDate: startDate,
        endDate: endDate,
        minAmount: minAmount,
        maxAmount: maxAmount,
        from_address: fromAddress,
        to_address: toAddress,
      });
    } else {
      // Set params without address fields if they are empty
      setParams({
        ...params,
        startDate: startDate,
        endDate: endDate,
        minAmount: minAmount,
        maxAmount: maxAmount,
        from_address: null, // or null, depending on how your API handles these values
        to_address: null, // or null
      });
    }
  }, [startDate, endDate, fromAddress, toAddress, minAmount, maxAmount]);

  // total pages of data
  useEffect(() => {
    if (bridgeData) {
      setTotalPages(bridgeData.totalPages);
    }
  }, [bridgeData]);

  useEffect(() => {
    setParams((prevParams) => ({
      ...prevParams,
      page,
      limit,
      search,
      type: messageType === "large_amount" ? null : messageType,
      sortOrder: sortOrder ? "desc" : "asc",
      sortColumn: sortColumn,
      large_amount: messageType === "large_amount" || null,
    }));
  }, [search, page, limit, messageType, sortColumn, sortOrder]);

  useEffect(() => {
    if (search?.trim().length > 0) {
      isLoadingGetData && refetchGetData();
    }
  }, [search, page, limit, isLoadingGetData, refetchGetData]);

  if (isLoadingGetData) return <Loader />;
  
  return (
    <Fragment>
      <section className="common-table custom-bridge">
        <Card>
          <CardHeader>
            <h4>Bridge Transfer</h4>
          </CardHeader>
          <CardBody>
            <TableTop
              advanceFilter={{ setShowFilter: setShowFilter, showFilter: showFilter }}
              limit={limit}
              searchTerm={searchTerm}
              setLimit={setLimit}
              setPage={setPage}
              setSearchTerm={setSearchTerm}
              dropdowns={[
                <div className="bridge-tabs">
                  <MessageTabs handleMessage={handleMessage} messageType={messageType} setMessageType={setMessageType} />
                </div>,
              ]}
            />
            {showFilter ? <AdvanceFilter refreshedData={refreshedData} refreshData={refreshData} advancedFilter={advancedFilter} setAdvancedFilter={setAdvancedFilter} setParams={setParams} params={params} /> : null}
            <div className="custom-scrollbar table-responsive">
              <BridgeTransferDataTable isLoadingGetData={isLoadingGetData} bridgeData={bridgeData} />
            </div>
            {!isLoadingGetData && bridgeData?.data?.length > 0 && <CommonPagination totalPages={totalPages} currentPage={page} setCurrentPage={setPage} />}
          </CardBody>
        </Card>
      </section>
      {editModal && <EditModal refetchTransaction={refetchGetData} />}
    </Fragment>
  );
};

export default BridgeTransferContainer;
