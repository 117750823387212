import React from "react";
import { useLocation } from "react-router-dom";
import { useBudget } from "../../../Context/BudgetContext";
import AvailBVIPayments from "./AvailBVIPayments";
import AvailCaymanPayments from "./AvailCaymanPayments";
import AvailDIFCPayments from "./AvailDifcPayments";
import AvailWalletPayments from "./AvailWalletPayments";
import BankPaymentsTable from "./BankPayments";
import CreditCardPayments from "./CreditCardPayments";
import EthereumPayments from "./EthereumPayments";

const BudgetTables = ({ data, isLoadingGetData, setEditMode }) => {
  const { pathname } = useLocation();
  const type = pathname.split("/")[pathname.split("/")?.length - 1] || "bank_payments";

  const { toggleEdit, setEditBudgetData, toggleDelete, toggleAgreementDeleteModal, setUploadId } = useBudget();

  const handleEdit = (id) => {
    setEditMode(true);
    const singleEditData = data?.data?.find((item) => item?.id === id);
    if (singleEditData) {
      toggleEdit();
      setEditBudgetData(singleEditData);
    }
  };

  const handleDelete = (id) => {
    if (id) {
      toggleDelete();
      setEditBudgetData({ type: type, id: id });
    }
  };

  const removeAgreement = (id) => {
    toggleAgreementDeleteModal();
    setUploadId(id);
  };

  const allTables = {
    bank_payments: <BankPaymentsTable data={data} isLoadingGetData={isLoadingGetData} handleEdit={handleEdit} handleDelete={handleDelete} />,
    credit_card_payments: <CreditCardPayments data={data} isLoadingGetData={isLoadingGetData} setEditMode={handleEdit} handleDelete={handleDelete} />,
    ethereum_wallet: <EthereumPayments data={data} isLoadingGetData={isLoadingGetData} setEditMode={handleEdit} handleDelete={handleDelete} />,
    avail_wallet: <AvailWalletPayments data={data} isLoadingGetData={isLoadingGetData} setEditMode={handleEdit} handleDelete={handleDelete} />,
    avail_difc: <AvailDIFCPayments data={data} isLoadingGetData={isLoadingGetData} setEditMode={handleEdit} handleDelete={handleDelete} handleRemoveAgreement={removeAgreement} />,
    avail_cayman: <AvailCaymanPayments data={data} isLoadingGetData={isLoadingGetData} setEditMode={handleEdit} handleDelete={handleDelete} handleRemoveAgreement={removeAgreement} />,
    avail_bvi: <AvailBVIPayments data={data} isLoadingGetData={isLoadingGetData} setEditMode={handleEdit} handleDelete={handleDelete} handleRemoveAgreement={removeAgreement} />,
  };
  return type ? allTables[type] : null;
};

export default BudgetTables;
