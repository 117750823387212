import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Card, CardBody, CardHeader } from "reactstrap";
import { userRole, userRoleDelete } from "../../../Api/ApiList";
import { useGetApi } from "../../../Api/useGetApi";
import { useRole } from "../../../Context/RoleContext";
import useDebounce from "../../../Hooks/useDebounce";
import CommonTable from "../../Common/CommonTable";
import DeleteModal from "../../Common/DeleteModal";
import TableTop from "../../Common/TableTop";
import RoleModal from "./RoleModal"; // Modal for adding a new role

const RoleDataTable = () => {
  const { setEditMode, searchTerm, setSearchTerm, deleteModal, toggleDelete, editUserData, setEditUserData } = useRole();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(25);
  const [params, setParams] = useState({});
  const search = useDebounce(searchTerm?.trim(), 500);

  const navigate = useNavigate();

  const { data: userRoleData, refetch: refetchuserRoleData, isLoading: isLoadingGetData } = useGetApi({ url: userRole, params, options: { enabled: !!Object.keys(params)?.length } });

  const handleEdit = (id) => {
    const singleEditData = userRoleData.data.find((item) => item?.id === id);
    if (singleEditData) {
      navigate("/users/role_form");
      setEditUserData(singleEditData);
      // Perform edit action here
      setEditMode(true);
    }
  };

  const toggleModal = () => setIsModalOpen(!isModalOpen);

  const handleDelete = (id) => {
    const singleEditData = userRoleData?.data?.find((item) => item?.id === id);
    if (singleEditData) {
      toggleDelete();
      setEditUserData(singleEditData);
    }
  };

  const columns = [
    {
      key: "name",
      label: "Name",
    },
    {
      key: "created_at",
      label: "Created At",
      type: "date",
    },
  ];

  useEffect(() => {
    setParams((prevParams) => ({
      ...prevParams,
      page,
      limit,
      search,
    }));
  }, [search, page, limit]);

  useEffect(() => {
    if (search?.trim().length > 0) {
      isLoadingGetData && refetchuserRoleData();
    }
  }, [search, page, limit, isLoadingGetData, refetchuserRoleData]);

  return (
    <Card>
      <CardHeader>
        <div className="d-flex justify-content-between align-items-center ">
          <h4>Roles</h4>
        </div>
      </CardHeader>
      <CardBody>
        <TableTop
          limit={limit}
          searchTerm={searchTerm}
          setLimit={setLimit}
          setPage={setPage}
          setSearchTerm={setSearchTerm}
          extraComponents={[
            {
              jsx: (
                <Button
                  color="role-button"
                  onClick={() => {
                    setEditMode(false);
                    navigate("/users/role_form");
                  }}
                >
                  + Add Role
                </Button>
              ),
            },
          ]}
        />

        <div className="custom-scrollbar table-responsive">
          <CommonTable serialNo columns={columns} data={userRoleData?.data || []} isLoading={isLoadingGetData} onEdit={handleEdit} tableClass="transaction-table" onDelete={handleDelete} />
          {deleteModal && <DeleteModal isOpen={deleteModal} deleteEndpoint={userRoleDelete} itemData={{ id: editUserData?.id }} itemType="User" toggleModal={toggleDelete} refetchCallback={refetchuserRoleData} />}
          {/* Modal for Adding/Editing Role */}
        </div>
        <RoleModal isOpen={isModalOpen} toggle={toggleModal} />
      </CardBody>
    </Card>
  );
};

export default RoleDataTable;
