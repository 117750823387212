import React from "react";
import { useBudget } from "../../../Context/BudgetContext";
import usePermissionCheck from "../../../Hooks/usePermissionCheck";
import { handleCapitalizeMultiple } from "../../../Utils";
import CommonTable from "../../Common/CommonTable";

const CreditCardPayments = ({ data, isLoadingGetData, setEditMode, handleDelete }) => {
  const { handleSort, sortOrder } = useBudget();
  const [edit, destroy] = usePermissionCheck(["edit", "destroy"], "budget");

  const columns = [
    {
      key: "payment_description",
      label: "Payment Description",
      isSortable: true,
    },
    {
      key: "payment_date",
      label: "Payment Date",
      type: "date",
      isSortable: true,
    },
    {
      key: "payment_source",
      label: "Payment Source",
    },
    {
      key: "currency",
      label: "Currency",
    },
    {
      key: "amount",
      label: "Amount",
      isSortable: true,
      type: "amount",
    },
    {
      key: "expense_category",
      label: "Expense Category",
    },
    {
      key: "expense_type",
      label: "Expense Type",
      isSortable: true,
    },
    {
      key: "amountUSD",
      label: "Amount (USD)",
      isSortable: true,
      type: "amount",
    },
    {
      key: "status",
      label: "Status",
      isSortable: true,
      render: (item) => <span className={`badge ${item?.status === "Paid" || item?.status === "Completed" ? "badge-light-success" : item?.status === "Expired" || item?.status === "Cancelled" ? "badge-light-danger" : "badge-light-warning"}`}> {handleCapitalizeMultiple(item?.status)} </span>,
    },
  ];

  return <CommonTable serialNo handleSort={handleSort} sortOrder={sortOrder} tableClass={"budget-table"} data={data?.data || []} columns={columns} showActions={true} onDelete={destroy ? handleDelete : false} onEdit={edit ? setEditMode : false} isLoading={isLoadingGetData} />;
};

export default CreditCardPayments;
