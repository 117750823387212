import React from "react";
import { useUsers } from "../../../Context/UsersContext";
import usePermissionCheck from "../../../Hooks/usePermissionCheck";
import CommonTable from "../../Common/CommonTable";

const UsersDataTable = ({ handleSort, sortOrder, isLoadingGetData, userData, setEditMode }) => {
  const [edit, destroy] = usePermissionCheck(["edit", "destroy", "create"], "user");
  const { toggleEdit, setEditUserData, toggleDelete } = useUsers();

  const handleEdit = (id) => {
    setEditMode(true);

    const singleEditData = userData?.find((item) => item?.id === id);
    if (singleEditData) {
      toggleEdit();
      setEditUserData(singleEditData);
    }
  };

  const handleDelete = (id) => {
    const singleEditData = userData?.find((item) => item?.id === id);
    if (singleEditData) {
      toggleDelete();
      setEditUserData(singleEditData);
    }
  };

  const columns = [
    {
      key: "name",
      label: "Name",
      // isSortable: true,
    },
    {
      key: "email",
      label: "Email",
      // isSortable: true,
    },

    {
      key: "role.name",
      label: "Role",
      render: (item) => <span className="badge badge-success">{item?.role?.name?.toUpperCase()}</span>,
    },
    {
      key: "created_at",
      label: "Created At",
      // isSortable: true,
      type: "date",
    },
  ];

  return <CommonTable serialNo columns={columns} data={userData || []} isLoading={isLoadingGetData} handleSort={handleSort} sortOrder={sortOrder} onDelete={destroy ? handleDelete : false} onEdit={edit ? handleEdit : false} tableClass="transaction-table" />;
};

export default UsersDataTable;
