import React from "react";
import { useForm } from "react-hook-form";
import { Button, Col, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { useRole } from "../../../Context/RoleContext";

const RoleModal = () => {
  const { editModal, toggleEdit } = useRole();
  const { register, handleSubmit } = useForm();

  const onSubmit = (data) => {
    toggleEdit(); // Close modal after save
  };

  const handleBack = () => {
    toggleEdit(); // Close the modal without saving
  };

  return (
    <Modal size="lg" centered isOpen={editModal} toggle={toggleEdit} className="transaction-modal">
      <ModalHeader toggle={toggleEdit}>Add Role</ModalHeader>
      <Form noValidate onSubmit={handleSubmit(onSubmit)}>
        <ModalBody>
          <Row className="g-3">
            <Col sm="12">
              <FormGroup className="form-group">
                <Label className="mb-2 form-label">Name *</Label>
                <input {...register("roleName")} className="form-control search-transaction" type="text" placeholder="Role Name" />
              </FormGroup>
            </Col>
            <Col sm="12">
              <FormGroup className="form-group">
                <Label className="mb-2 form-label">Permissions *</Label>
                <div className="permissions">
                  <Label>Users:</Label>
                  <div className="d-flex">
                    <FormGroup check>
                      <Label check>
                        <Input type="checkbox" {...register("permissions.users.all")} /> All
                      </Label>
                    </FormGroup>
                    <FormGroup check className="ms-3">
                      <Label check>
                        <Input type="checkbox" {...register("permissions.users.index")} /> Index
                      </Label>
                    </FormGroup>
                    <FormGroup check className="ms-3">
                      <Label check>
                        <Input type="checkbox" {...register("permissions.users.create")} /> Create
                      </Label>
                    </FormGroup>
                    <FormGroup check className="ms-3">
                      <Label check>
                        <Input type="checkbox" {...register("permissions.users.edit")} /> Edit
                      </Label>
                    </FormGroup>
                    <FormGroup check className="ms-3">
                      <Label check>
                        <Input type="checkbox" {...register("permissions.users.destroy")} /> Destroy
                      </Label>
                    </FormGroup>
                  </div>
                </div>
              </FormGroup>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={handleBack}>
            Back
          </Button>
          <Button color="primary" type="submit">
            Save
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default RoleModal;
