import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Button, Col, Form, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { updateTransactionApi } from "../../Api/ApiList";
import { usePerformMutation } from "../../Api/PerformMutation";
import { useTransaction } from "../../Context/TransactionContext";

const EditModal = ({ refetchTransaction }) => {
  const { editModal, toggleEdit, editTransactionData } = useTransaction();
  const [loading, setLoading] = useState(false); // State to track loading status
  const { mutate } = usePerformMutation(
    () => {
      refetchTransaction();
      reset();
      toggleEdit();
    },
    () => {
      setLoading(false);
    }
  );
  const { register, handleSubmit, reset } = useForm({
    defaultValues: {
      id: editTransactionData?.id,
      block_number: editTransactionData?.block_number,
      event_id: editTransactionData?.event_id,
      amount: editTransactionData?.amount,
      from_account: editTransactionData?.from_account,
      to_account: editTransactionData?.to_account,
    },
  });

  const handleTransaction = async (data) => {
    setLoading(true);
    const { from_account, from_label, to_account, to_label, amount } = data;
    const conditionalData = {
      from_address: from_label !== "" && to_label !== "" ? from_account : to_label !== "" ? null : from_account,
      from_label,
      to_address: from_label !== "" && to_label !== "" ? to_account : from_label !== "" ? null : to_account,
      to_label,
      starting_balance: amount,
    };
    await mutate({ url: updateTransactionApi, data: conditionalData });
    setLoading(false);
  };

  return (
    <Modal size="lg" centered isOpen={editModal} toggle={toggleEdit} className="transaction-modal">
      <ModalHeader toggle={toggleEdit}>Edit Transaction</ModalHeader>
      <Form onSubmit={handleSubmit(handleTransaction)} noValidate>
        <ModalBody>
          <Row className="g-3">
            <Col sm="6">
              <FormGroup className="form-group">
                <Label className="mb-2 form-label">No :</Label>
                <input {...register("id")} disabled className="form-control search-transaction" type="text" placeholder="Enter number" value={editTransactionData?.id} />
              </FormGroup>
            </Col>
            <Col sm="6">
              <FormGroup className="form-group">
                <label className="mb-2 form-label">Block No :</label>
                <input disabled {...register("block_number")} className="form-control search-transaction" type="text" placeholder="Enter block number" value={editTransactionData?.block_number} />
              </FormGroup>
            </Col>
            <Col sm="6">
              <FormGroup className="form-group">
                <label className="mb-2 form-label">Event Id :</label>
                <input disabled {...register("event_id")} className="form-control search-transaction" type="text" placeholder="Enter ID" value={editTransactionData?.event_id} />
              </FormGroup>
            </Col>
            <Col sm="6">
              <FormGroup className="form-group">
                <label className="mb-2 form-label">Amount :</label>
                <input disabled {...register("amount")} className="form-control search-transaction" type="text" placeholder="Enter amount" value={editTransactionData?.amount} />
              </FormGroup>
            </Col>
            <Col sm="12">
              <FormGroup className="form-group">
                <label className="mb-2 form-label">From Account :</label>
                <input disabled {...register("from_account")} className="form-control search-transaction" type="text" placeholder="Enter from account" value={editTransactionData?.from_account} />
              </FormGroup>
            </Col>
            <Col sm="12">
              <FormGroup className="form-group">
                <label className="mb-2 form-label">To Account :</label>
                <input disabled {...register("to_account")} className="form-control search-transaction" type="text" placeholder="Enter to account" value={editTransactionData?.to_account} />
              </FormGroup>
            </Col>
            <Col sm="6">
              <FormGroup className="form-group">
                <label className="mb-2 form-label">From Label :</label>
                <input {...register("from_label")} className="form-control search-transaction" type="text" placeholder="Enter from label" defaultValue={editTransactionData?.from_label} />
              </FormGroup>
            </Col>
            <Col sm="6">
              <FormGroup className="form-group">
                <label className="mb-2 form-label">To Label :</label>
                <input {...register("to_label")} className="form-control search-transaction" type="text" placeholder="Enter to label" defaultValue={editTransactionData?.to_label} />
              </FormGroup>
            </Col>
            <Col sm="6">
              <FormGroup className="form-group">
                <label className="mb-2 form-label">Starting Balance :</label>
                <input {...register("amount")} className="form-control search-transaction" type="number" placeholder="Enter your balance" />
              </FormGroup>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="" type="submit">
            Update {loading && <i className="fa-solid fa-circle-notch fa-spin"></i>}
          </Button>
          <Button color="light" className="border border-1" onClick={toggleEdit}>
            Cancel
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default EditModal;
