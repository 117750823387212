import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Button, Col, Form, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { userCreate, userRole, userUpdate } from "../../../Api/ApiList";
import { usePerformMutation } from "../../../Api/PerformMutation";
import { useGetApi } from "../../../Api/useGetApi";
import { useUsers } from "../../../Context/UsersContext";

const EditModal = ({ refetchUser, isEditMode }) => {
  const { editModal, toggleEdit, editUserData } = useUsers();
  const [loading, setLoading] = useState(false); // State to track loading status
  const { data: userRoleData } = useGetApi({ url: userRole });

  const {
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      id: "",
      name: "",
      email: "",
      password: "",
      confirm_password: "",
      phone: "",
      role_id: "",
      status: true,
    },
  });

  const { mutate } = usePerformMutation(
    () => {
      refetchUser();
      reset();
      toggleEdit();
    },
    () => {
      setLoading(false);
    }
  );

  const handleUser = async (data) => {
    const apiEndPoint = isEditMode ? userUpdate : userCreate;
    setLoading(true);
    await mutate({ url: apiEndPoint, data: { ...data, status: data?.status ? "active" : "inactive" }, isUpdate: isEditMode });
    setLoading(false);
  };

  useEffect(() => {
    if (isEditMode && editUserData) {
      setValue("id", editUserData.id);
      setValue("name", editUserData.name);
      setValue("email", editUserData.email);
      setValue("password", editUserData.password);
      setValue("confirm_password", editUserData.confirm_password);
      setValue("phone", editUserData.phone);
      setValue("status", editUserData.status == "active" ? 1 : 0);
      setValue("role_id", editUserData.role_id);
    } else {
      reset();
    }
  }, [isEditMode, editUserData, setValue, reset]);

  return (
    <Modal size="lg" centered isOpen={editModal} toggle={toggleEdit} className="transaction-modal">
      <ModalHeader toggle={toggleEdit}>{isEditMode ? "Update User" : "Add User"}</ModalHeader>
      <Form onSubmit={handleSubmit(handleUser)} noValidate>
        <ModalBody>
          <Row className="g-3">
            <Col sm="12">
              <FormGroup className="form-group">
                <Label className="mb-2 form-label">Name :</Label>
                <input {...register("name", { required: "Name is required" })} className={`form-control search-users  ${errors.name ? "is-invalid" : ""}`} type="text" placeholder="Enter name" />
                {errors.name && <div className="invalid-feedback">{errors.name.message}</div>}
              </FormGroup>
            </Col>
            <Col sm="12">
              <FormGroup className="form-group">
                <Label className="mb-2 form-label">Email :</Label>
                <input {...register("email", { required: "email is required" })} className={`form-control search-users  ${errors.email ? "is-invalid" : ""}`} type="text" placeholder="Enter email" />
                {errors.email && <div className="invalid-feedback">{errors.email.message}</div>}
              </FormGroup>
            </Col>

            {!isEditMode && (
              <>
                <Col sm="12">
                  <FormGroup className="form-group">
                    <label className="mb-2 form-label">Password :</label>
                    <input {...register("password", { required: "Password is required" })} className={`form-control search-users  ${errors.password ? "is-invalid" : ""}`} type="password" placeholder="Enter password" />
                    {errors.password && <div className="invalid-feedback">{errors.password.message}</div>}
                  </FormGroup>
                </Col>
                <Col sm="12">
                  <FormGroup className="form-group">
                    <label className="mb-2 form-label">Confirm Password :</label>
                    <input {...register("confirm_password", { required: "Confirm password require", validate: (value) => value === watch("password") || "Passwords do not match" })} className={`form-control search-users  ${errors.confirm_password ? "is-invalid" : ""}`} type="password" placeholder="Enter confirm password" />
                    {errors.confirm_password && <div className="invalid-feedback">{errors.confirm_password.message}</div>}
                  </FormGroup>
                </Col>
              </>
            )}
            <Col sm="12">
              <FormGroup className="form-group">
                <label className="mb-2 form-label">Role :</label>
                <select name="role" {...register("role_id", { required: "Role is required" })} id="role" className={`form-control search-users ${errors.role ? "is-invalid" : ""}`}>
                  <option value="">Select a role</option>
                  {userRoleData?.data?.map((role) => (
                    <option key={role?.id} value={role?.id}>
                      {role.name}
                    </option>
                  ))}
                </select>
                {errors.role && <div className="invalid-feedback">{errors.role_id.message}</div>}
              </FormGroup>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="" type="submit">
            {isEditMode ? "Update" : "Add"} {loading && <i className="fa-solid fa-circle-notch fa-spin"></i>}
          </Button>
          <Button color="light" className="border border-1" onClick={toggleEdit}>
            Cancel
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default EditModal;
