// DynamicHeader.js
import React, { Fragment } from "react";
import { Plus } from "react-feather";
import { Button, Col, Row } from "reactstrap";
import PageLimitDropdown from "../Common/PageLimitDropdown";

const TableTop = ({ limit, setLimit, setPage, searchTerm, setSearchTerm, extraComponents = [], dropdowns = [], advanceFilter = {}, importFile = false, exportFile = false ,noPaginate,noSearchbar}) => {
  return (
    <> 
      <div className="p-3 pt-0 g-3">
        <Row>
          <Col className="col-auto">
            <div className="d-flex align-items-center gap-2 custom-entries-box">
              {!noPaginate &&<PageLimitDropdown limit={limit} setLimit={setLimit} setPage={setPage} />}
              {dropdowns.map((dropdown, index) => (
                <>{dropdown}</> 
              ))}
            </div>
          </Col>
          <Col className="search-box"> 
            {extraComponents
              .filter((item, index) => item?.position === "beforeSearch")
              ?.map((item, index) => (
                <Fragment>{item?.jsx}</Fragment>
              ))}
           {!noSearchbar&& <div className="common-flex">
              <p className="mb-0">Search :</p>
              <div className="d-flex align-items-center">
                <input type="text" className="form-control search-transaction" placeholder="Search ..." value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
              </div>
            </div>}

            {Object.keys(advanceFilter)?.length ? (
              <>
                <div className="light-box" onClick={() => advanceFilter?.setShowFilter(!advanceFilter?.showFilter)}>
                  {advanceFilter?.showFilter ? <i className="fa-solid fa-xmark" /> : <i className="fa-solid fa-filter" />}
                </div>
              </>
            ) : null}

            {importFile ? (
              <Button color="border border-1" onClick={() => importFile()}>
                <Plus size={15} /> Import
              </Button>
            ) : null}

            {exportFile ? (
              <Button color="transparent" className="export-btn" onClick={() => exportFile()}>
                <i className="fa-solid fa-file-export"></i>
                Export
              </Button>
            ) : null}

            {/* {extraComponents?.map((item, index) => (
              <Fragment>{item?.jsx}</Fragment>
            ))} */}

            {extraComponents
              ?.filter((item) => !item?.position)
              ?.map((item, index) => (
                <Fragment>{item?.jsx}</Fragment>
              ))}
          </Col>
        </Row>
      </div>
    </>
  );
};

export default TableTop;
