import React from "react";
import { handleCapitalizeMultiple } from "../../Utils";
import CommonTable from "../Common/CommonTable";

const ExpensesDataTable = ({ isLoadingGetData, expensesData }) => {
  const columns = [
    { key: "expense_id", label: "Expense Id", type: "address", truncate: true },
    { key: "reported_by.full_name", label: "Reported By" },
    { key: "description", label: "Description" },
    { key: "type", label: "Type", render: (item) => handleCapitalizeMultiple(item?.type) },
    { key: "total_amount", label: "Amount", type: "amount" },
    { key: "status", label: "Status", render: (item) => <span className={`badge badge-light-${item?.status == "paid" ? "success" : item?.status == "pending" || item?.status == "approved" ? "warning" : "danger"}`}>{handleCapitalizeMultiple(item?.status)}</span> },
  ];

  return <CommonTable columns={columns} data={expensesData?.data || []} serialNo={true} isLoading={isLoadingGetData} tableClass="transaction-table transfer-table" />;
};

export default ExpensesDataTable;
