import { useQuery } from "@tanstack/react-query";
import axiosInstance, { useErrorMessageHandler } from "./middleware";

export const useGetApi = ({ url, params, options, successFunction, navigate }) => {
  const { handleError } = useErrorMessageHandler(navigate);
  return useQuery({
    queryKey: [url, params],
    queryFn: async () => {
      try {
        const response = await axiosInstance.get(url, { params });
        return response.data;
      } catch (error) {
        handleError(error, navigate);
      }
    },

    ...options,
  });
};
