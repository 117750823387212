import React, { createContext, useContext, useState } from "react";

const RoleContext = createContext();

export const RoleProvider = ({ children }) => {
  const [editModal, setEditModal] = useState(false);
  const toggleEdit = () => setEditModal((prev) => !prev);
  const [importModal, setImportModal] = useState(false);
  const toggleImport = () => setImportModal((prev) => !prev);
  const [editUserData, setEditUserData] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const toggleDelete = () => setDeleteModal((prev) => !prev);
  const [editMode, setEditMode] = useState(false);

  return <RoleContext.Provider value={{ editMode, setEditMode, deleteModal, setDeleteModal, toggleDelete, editModal, setEditModal, toggleEdit, importModal, setImportModal, toggleImport, setEditUserData, editUserData, searchTerm, setSearchTerm }}>{children}</RoleContext.Provider>;
};

export const useRole = () => {
  return useContext(RoleContext);
};
