import React from "react";
import { Badge } from "reactstrap";
import CommonTable from "../Common/CommonTable";
import InnerLoader from "../../Layout/InnerLoader";

const NotificationsTable = ({ notificationsData, isLoadingGetData }) => {
  const columns = [
    {
      key: "notification",
      label: "Notification",
      render: (item) => (
        <div className={`${item?.mark_as_read ? "notify-push" : "notify-visible"}`}>
          <p>{item.title}</p>
          <span>{item.description}</span>
        </div>
      ),
    },
    {
      key: "status",
      label: "Status",
      render: (item) => <span className={`badge badge-light-${item?.status === "completed" ? "success" : "warning"}`}>{item?.status === "completed" ? "Executed" : "Pending"}</span>,
    },
  ];

  return <CommonTable serialNo columns={columns} data={notificationsData?.data} isLoading={isLoadingGetData} loaderComponent={<InnerLoader />} tableClass="notification-table mb-0" />;
};
  
export default NotificationsTable;
