import React, { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Card, CardBody, CardHeader, Tooltip } from "reactstrap";
import { refreshAccountApi, transactionApi } from "../../Api/ApiList";
import { useGetApi } from "../../Api/useGetApi";
import { useTransaction } from "../../Context/TransactionContext";
import useDebounce from "../../Hooks/useDebounce";
import useDebouncedObject from "../../Hooks/useDebounceObject";
import { toMilliseconds } from "../../Utils";
import AdvanceFilter from "../Common/AdvanceFilter";
import CommonPagination from "../Common/CommonPagination";
import { calculateRemainingTime, exportToCSV, handleRefresh } from "../Common/CustomFunctions";
import TableTop from "../Common/TableTop";
import EditModal from "./EditModal";
import TransactionDataTable from "./TransactionDataTable";

const TransactionContainer = () => {
  const navigate = useNavigate();
  const localKey = "transactionLastRefreshTime";
  const REFRESH_INTERVAL = toMilliseconds(1, 0, 0);
  const [totalPages, setTotalPages] = useState();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(25);
  const [sortOrder, setSortOrder] = useState(true);
  const [sortColumn, setSortColumn] = useState("timestamp");
  const [showFilter, setShowFilter] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [tooltipContent, setTooltipContent] = useState("Refresh"); // Tooltip content
  const [isRefreshDisabled, setIsRefreshDisabled] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);
  const [remainingTime, setRemainingTime] = useState();
  const [currentState, setCurrentState] = useState("All");

  const [advancedFilter, setAdvancedFilter] = useState({
    startDate: null,
    endDate: null,
    minAmount: null,
    maxAmount: null,
    fromAddress: null,
    toAddress: null,
  });

  const debouncedObject = useDebouncedObject(advancedFilter, 500);
  const { startDate = null, endDate = null, minAmount = null, maxAmount = null, fromAddress = null, toAddress = null } = debouncedObject;

  const { editModal, searchTerm, setSearchTerm } = useTransaction();
  const search = useDebounce(searchTerm?.trim(), 500);
  const [params, setParams] = useState({});
  const { isLoading: isLoadingGetData, data: transactionData, refetch: refetchGetData } = useGetApi({ url: `/${transactionApi}`, params, options: { enabled: !!Object.keys(params).length }, navigate: navigate });
  const { data: refreshedData, refetch: refreshData } = useGetApi({ url: refreshAccountApi, options: { enabled: false, refetchOnMount: false } });

  const handleSort = (column) => {
    setSortColumn(column);
    setSortOrder(!sortOrder);
  };

  useEffect(() => {
    if (searchTerm.trim() === "") {
      setPage(1);
    }
  }, [searchTerm]);

  // send advance filter params in api
  useEffect(() => {
    // Check if `from_address` and `to_address` are not empty
    if (fromAddress?.trim() !== "" && toAddress?.trim() !== "") {
      setParams({
        ...params,
        startDate: startDate,
        endDate: endDate,
        minAmount: minAmount,
        maxAmount: maxAmount,
        from_address: fromAddress,
        to_address: toAddress,
      });
    } else {
      // Set params without address fields if they are empty
      setParams({
        ...params,
        startDate: startDate,
        endDate: endDate,
        minAmount: minAmount,
        maxAmount: maxAmount,
        from_address: null, // or null, depending on how your API handles these values
        to_address: null, // or null
      });
    }
  }, [startDate, endDate, fromAddress, toAddress, minAmount, maxAmount]);

  // total pages of data
  useEffect(() => {
    if (transactionData) {
      setTotalPages(transactionData.totalPages);
    }
  }, [transactionData]);

  useEffect(() => {
    setParams((prevParams) => ({
      ...prevParams,
      page,
      limit,
      search,
      sortOrder: sortOrder ? "desc" : "asc",
      sortColumn: sortColumn,
      large_amount: currentState === "All" ? null : true,
    }));
  }, [search, page, limit, sortOrder, sortColumn, currentState]);

  useEffect(() => {
    if (search.trim().length > 0) {
      isLoadingGetData && refetchGetData();
    }
  }, [search, page, limit, sortColumn, isLoadingGetData, sortOrder, refetchGetData]);

  useEffect(() => {
    const lastRefreshTime = localStorage.getItem(localKey);
    if (lastRefreshTime) {
      const now = new Date().getTime();
      const timeSinceLastRefresh = now - lastRefreshTime;

      if (timeSinceLastRefresh < REFRESH_INTERVAL) {
        setIsRefreshDisabled(true);
        const intervalId = setInterval(() => {
          const remainingTime = calculateRemainingTime(lastRefreshTime);
          if (remainingTime) {
            setTooltipContent(`Refresh available in`);
            setRemainingTime(remainingTime);
          } else {
            setIsRefreshDisabled(false);
            setTooltipContent("Refresh");
            clearInterval(intervalId);
          }
        }, 1000);

        return () => clearInterval(intervalId);
      }
    }
  }, [REFRESH_INTERVAL]);

  const handleFilter = (e, setLimit, setPage) => {
    setCurrentState(e.target.value);
    // setPage(1);
  };

const csvDataExport  = ()=> {
  return exportToCSV(transactionData,'transactions')
}
  

  return (
    <Fragment>
      <section className="common-table">
        <Card>
          <CardHeader>
            <h4>Transactions</h4>
          </CardHeader>
          <CardBody>
            <TableTop
              limit={limit}
              advanceFilter={{ showFilter: showFilter, setShowFilter: setShowFilter }}
              searchTerm={searchTerm}
              setLimit={setLimit}
              setPage={setPage}
              setSearchTerm={setSearchTerm}
              exportFile={csvDataExport}
              extraComponents={[
                {
                  position: "beforeSearch",
                  jsx: (
                    <>
                      <select value={currentState} type="select" onChange={(e) => handleFilter(e)} className="form-select w-auto">
                        {["All", "Large Amount"].map((item) => (
                          <option key={item} value={item}>
                            {item}
                          </option>
                        ))}
                      </select>
                    </>
                  ),
                },
                {
                  jsx: (
                    <>
                      <span id="refresh-tooltip" style={{ cursor: "pointer" }}>
                        <Button disabled={isRefreshDisabled} color="transparent" className="p-0">
                          <div className="light-box" onClick={() => handleRefresh(localKey, refreshData, refetchGetData, setIsRefreshDisabled, REFRESH_INTERVAL, setTooltipContent, calculateRemainingTime, setRemainingTime)}>
                            <i className="fa-solid fa-rotate"></i>
                          </div>
                        </Button>
                      </span>
                      <Tooltip placement={"top"} isOpen={tooltipOpen} target={"refresh-tooltip"} toggle={toggle}>
                        <p>
                          {tooltipContent} <b>{remainingTime}</b>{" "}
                        </p>
                      </Tooltip>
                    </>
                  ),
                },
              ]}
            />

            {showFilter ? <AdvanceFilter refreshedData={refreshedData} refreshData={refreshData} advancedFilter={advancedFilter} setAdvancedFilter={setAdvancedFilter} setParams={setParams} params={params} /> : null}
            <div className="custom-scrollbar table-responsive">
              <TransactionDataTable handleSort={handleSort} isLoadingGetData={isLoadingGetData} sortOrder={sortOrder} transactionData={transactionData} />
            </div>
            {!isLoadingGetData && transactionData?.data?.length > 0 && <CommonPagination totalPages={totalPages} currentPage={page} setCurrentPage={setPage} />}
          </CardBody>
        </Card>
      </section>
      {editModal && <EditModal refetchTransaction={refetchGetData} />}
    </Fragment>
  );
};

export default TransactionContainer;
