import React from "react";
import { handleCapitalizeMultiple } from "../../Utils";
import CommonTable from "../Common/CommonTable";

const PeopleDataTable = ({ isLoadingGetData, peoplesData }) => {
  const columns = [
    { key: "people_id", label: "People Id", type: "address", truncate: true },
    { key: "full_name", label: "Person" },
    { key: "birth_date", label: "Birth Date", type: "date" },
    { key: "timezone", label: "Timezone" },
    { key: "country", label: "Country" },
    { key: "job_title", label: "Job Title" },
    { key: "hiring_type", label: "Hiring Type", render: (item) => handleCapitalizeMultiple(item?.hiring_type) },
    { key: "hiring_status", label: "Hiring Status", render: (item) => <span className={`badge badge-light-${item?.hiring_status == "active" ? "success" : "danger"}`}>{handleCapitalizeMultiple(item?.hiring_status)}</span> },
  ];

  return <CommonTable columns={columns} data={peoplesData?.data || []} serialNo={true} isLoading={isLoadingGetData} tableClass="transaction-table transfer-table people-table" />;
};

export default PeopleDataTable;
