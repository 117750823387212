import React from "react";
import { Button, Modal, ModalBody, ModalFooter } from "reactstrap";
import useDelete from "../../Api/useDelete";

const DeleteModal = ({
  isOpen,
  toggleModal,
  itemData,
  deleteEndpoint,
  refetchCallback,
  extraFunction,
  itemType = "item", // You can customize the type of item being deleted
}) => {
  const { loading, handleDelete } = useDelete(deleteEndpoint, refetchCallback, toggleModal, extraFunction);

  return (
    <Modal isOpen={isOpen} toggle={toggleModal} className="account-deletion" centered>
      <ModalBody>
        <div className="trash-box">
          <i className="fa-solid fa-trash-can"></i>
          <h3>Do you really want to remove this {itemType}?</h3>
          <p>This operation will permanently remove the item and is not reversible. Are you sure you want to proceed?</p>
        </div>
      </ModalBody>
      <ModalFooter className="pt-0">
        <div className="common-btn-box">
          <Button color="danger" onClick={() => handleDelete(itemData)} disabled={loading}>
            Delete {loading && <i className="fa-solid fa-circle-notch fa-spin"></i>}
          </Button>
          <Button color="secondary" onClick={toggleModal} disabled={loading}>
            Cancel
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default DeleteModal;
