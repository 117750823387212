import React from "react";
import { handleCapitalizeMultiple } from "../../../Utils";
import CommonTable from "../../Common/CommonTable";

const PaymentHistoryDataTable = ({ isLoadingGetData, peoplesData }) => {
  const columns = [
    { key: "paid_at", label: "Paid At", type: "date" },
    { key: "label", label: "Label" },
    { key: "workers", label: "Workers", render: (item) => (item?.workers?.length ? item?.workers?.map((item) => item?.name)?.join(",") : "--") },
    { key: "payment_currency", label: "Payment Currency" },
    { key: "total", label: "Amount", type: "amount" },
    { key: "status", label: "Status", render: (item) => <span className={`badge badge-light-${item?.status == "paid" ? "success" : item?.status == "pending" ? "warning" : "danger"}`}>{handleCapitalizeMultiple(item?.status)}</span> },
  ];

  return <CommonTable columns={columns} data={peoplesData?.data || []} serialNo={true} isLoading={isLoadingGetData} tableClass="transaction-table transfer-table people-table" />;
};

export default PaymentHistoryDataTable;
