import { getNotificationsApi } from "./ApiList";
import { useGetApi } from "./useGetApi"; // Assuming this is your custom hook

const useNotifications = () => {
  const {
    isLoading,
    data: notificationsData,
    refetch: refetchGetData,
  } = useGetApi({
    url: getNotificationsApi,
    options: {
      refetchOnWindowFocus: false,
      enabled: false,
    },
  });

  return { isLoading, notificationsData, refetchGetData };
};

export default useNotifications;
