import { useState, useEffect } from "react";

const useDebouncedObject = (obj, delay) => {
  const [debouncedValues, setDebouncedValues] = useState({});

  useEffect(() => {
    const handler = setTimeout(() => {
      const debouncedObj = {};

      for (const key in obj) {
        debouncedObj[key] = obj[key]; // Directly copy the current values
      }

      setDebouncedValues(debouncedObj);
    }, delay);

    return () => { 
      clearTimeout(handler);
    };
  }, [obj, delay]);

  return debouncedValues;
};

export default useDebouncedObject;
