import React, { Fragment } from "react";
import { useRole } from "../../../Context/RoleContext";
import RoleDataTable from "./RoleDataTable";
import RoleModal from "./RoleModal";

const UserRoleContainer = () => {
  const { editModal, toggleEdit, editMode } = useRole();

  return (
    <Fragment>
      <section className="common-table">
        <div>
          <RoleDataTable />
        </div>
      </section>
      {editModal && <RoleModal isEditMode={editMode} toggleEdit={toggleEdit} />}
    </Fragment>
  );
};
export default UserRoleContainer;
