import React from "react";
import { handleCapitalizeMultiple } from "../../../Utils";
import CommonTable from "../../Common/CommonTable";

const InvoicesTable = ({ isLoadingGetData, peoplesData }) => {
  const columns = [
    { key: "due_date", label: "Due Date", type: "date" },
    { key: "paid_at", label: "Paid At", type: "date" },
    { key: "label", label: "Label" },
    { key: "currency", label: "Currency" },
    { key: "contract_id", label: "Contactor Id" },
    { key: "total", label: "Amount", type: "amount" },
    { key: "status", label: "Status", render: (item) => <span className={`badge badge-light-${item?.status == "paid" ? "success" : item?.status == "pending" ? "warning" : "danger"}`}>{handleCapitalizeMultiple(item?.status)}</span> },
  ];

  return <CommonTable columns={columns} data={peoplesData?.data || []} serialNo={true} isLoading={isLoadingGetData} tableClass="transaction-table transfer-table people-table" />;
};

export default InvoicesTable;
