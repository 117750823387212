import React from "react";
import { useNavigate } from "react-router-dom";

const ErrorPage = () => {
  const navigate = useNavigate();
  return (
    <div className="error-wrapper">
      <div className="container">
        <div className="error-heading">
          {/* <h2 className="headline font-success">403</h2> */}

          <img src={`../../assets/svg/403.svg`} alt="" />
        </div>
        <div className="col-md-8 offset-md-2">
          <p className="sub-content">The page you are attempting to reach is currently not available. This may be because the page does not exist or has been moved.</p>
        </div>
        <div>
          <button onClick={() => navigate("/")} className="btn btn-success btn-lg">
            BACK TO HOME PAGE
          </button>
        </div>
      </div>
    </div>
  );
};

export default ErrorPage;
