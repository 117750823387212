import React from "react";
import { useTransaction } from "../../Context/TransactionContext";
import CommonTable from "../Common/CommonTable";

const TransactionDataTable = ({ handleSort, sortOrder, isLoadingGetData, transactionData }) => {
  const { toggleEdit, setEditTransactionData } = useTransaction();

  const handleEdit = (id) => {
    const singleEditData = transactionData?.data?.find((item) => item?.id === id);
    if (singleEditData) {
      toggleEdit();
      setEditTransactionData(singleEditData);
    }
  };

  const columns = [
    { key: "id", label: "No.", isSortable: true },
    {
      key: "extrinsic_id",
      label: "Extrinsic Id",
      isSortable: true,
      type: "link",
      redirectLinkPrefix: "https://avail.subscan.io/extrinsic/",
    },
    {
      key: "from_account",
      label: "From Account",
      type: "address",
      useTooltip: (item) => item.from_label,
      tooltipContent: "from_account",
      tooltipColor: "danger",
      redirectLinkPrefix: "https://avail.subscan.io/account/",
      truncate: true,
    },
    {
      key: "to_account",
      label: "To Account",
      type: "address",
      useTooltip: (item) => item.to_label,
      tooltipContent: "to_account",
      tooltipColor: "success",
      redirectLinkPrefix: "https://avail.subscan.io/account/",
      truncate: true,
    },
    {
      key: "amount",
      label: "Amount",
      isSortable: true,
      type: "amount",
    },
    {
      key: "timestamp",
      label: "Timestamp",
      isSortable: true,
      type: "date",
    },
  ];

  return <CommonTable  columns={columns} data={transactionData?.data || []} isLoading={isLoadingGetData} handleSort={handleSort} sortOrder={sortOrder} onEdit={handleEdit} tableClass="transaction-table" />;
};

export default TransactionDataTable;
